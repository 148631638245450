/* _font-awesome-overrule.scss */

i.fa,
i[class^="icon-"] {
    font-size:$base-font-size;
    line-height:$base-font-size;

    &.fa-info-circle {
        cursor:pointer;//help / info (for tooltips)
    }

    &.fa-lg {
        font-size:16px;
        line-height:16px;
    }

    &.fa-lg {
        font-size:20px;
        line-height:20px;
    }

    &.fa-bold {
        font-weight:bold;
        &:before {
            font-weight:bold;
        }
    }
}

.i-right {
    i.fa,
    i[class^="icon-"] {
        margin-left:$offset-1;
    }
}
.i-left {
    i.fa,
    i[class^="icon-"] {
        margin-right:$offset-1;
    }
}

.fa-dummy,
.fa-dummy-before:before {
    @include content-fa();
}