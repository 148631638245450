/* _base.scss */

html {
  // height:100%;
	// background-repeat: no-repeat;
  background: $color_bg-body;

	// background: -webkit-linear-gradient(top, rgb(209, 198, 132) 0%, rgb(233, 225, 188) 90%, rgb(235, 227, 190) 100%);
	// background: -o-linear-gradient(top, rgb(209, 198, 132) 0%, rgb(233, 225, 188) 90%, rgb(235, 227, 190) 100%);
	// background: -ms-linear-gradient(top, rgb(209, 198, 132) 0%, rgb(233, 225, 188) 90%, rgb(235, 227, 190) 100%);
	// background: -moz-linear-gradient(top, rgb(209, 198, 132) 0%, rgb(233, 225, 188) 90%, rgb(235, 227, 190) 100%);
	// background: linear-gradient(to bottom, rgb(209, 198, 132) 0%, rgb(233, 225, 188) 90%, rgb(235, 227, 190) 100%);
}

body {
	font-family: $base-font-family;
	font-size: $base-font-size;
	color: $base-font-color;
	//line-height:$base-font-line-height;
	// background:$color_bg-body;
	border-color: $color_layout-border;
	border: 0;
}
/* [END] body */

// .main .container {
// 	// background: $color_secondary-light;
// 	// background: white;
// 	&.item,
// 	&.items.Live,
// 	&.items.Archive {
// 		// background: $color_secondary-light;
// 	}	
// }

.container {
	width:100%;  

	@media (min-width: 1200px){
		width: 90%;//230313
	}
}

.main .container .sidebar {
	background: white;
}

.position-relative {
	position: relative;
}
.position-absolute {
	position: absolute;
}

.display-none {
	display: none;
}
.display-block {
	display: block;
}
.display-inline-block {
	display: inline-block;
}

.display-table {
	display: table;
}
.display-table-row {
	display: table-row;
}
.display-table-cell {
	display: table-cell;
}

.debug-bs {
	background: white url(./img/symmetric-grid-bootstrap.png) center center scroll;
	background-size: 100%;

	.debug-bs-col:nth-child(even) {
		background-color: rgba(255, 138, 138, 0.5);
	}
	.debug-bs-col:nth-child(even) {
		background-color: rgba(0, 191, 38, 0.5);
	}
}

hr {
	display: block;
	width: 100%;
	border-top: 1px solid $color_layout-border;
}

.bg-accent {
	background: $color_accent;
}

.bg-accent4 {
	background: $color_accent4;
}

.container.double-pad {
	@include resp-prop-half("padding-left", $offset-g2);
	@include resp-prop-half("padding-right", $offset-g2);
}

.content {
	padding: 0;

	.content-inner {
		@include resp-prop-half("padding", $offset-g2);
	}
}

section {
	.content-inner {
		@include resp-prop-half("padding-left", $offset-g2);
		@include resp-prop-half("padding-right", $offset-g2);
	}

	
}

.padding-y {
	@include resp-prop-half("padding-top", $offset-g2);
	@include resp-prop-half("padding-bottom", $offset-g2);
}
.padding-top {
	@include resp-prop-half("padding-top", $offset-g2);
}
.padding-bottom {
	@include resp-prop-half("padding-bottom", $offset-g2);
}

.pad-x-2 {
	@include resp-prop-half("padding-left", $offset-g2);
	@include resp-prop-half("padding-right", $offset-g2);
}

.pad-y {
	@include resp-prop-half("padding-top", $offset-g1);
	@include resp-prop-half("padding-bottom", $offset-g1);
}
.pad-y-2 {
	@include resp-prop-half("padding-top", $offset-g2);
	@include resp-prop-half("padding-bottom", $offset-g2);
}
.pad-bottom-2 {
	@include resp-prop-half("padding-bottom", $offset-g2);
}

.cull-2do {
	display:none;
}