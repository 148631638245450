/* _offsets.scss */

.margin-0 {
  margin: 0 !important;
}
.margin-x-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.padding-0 {
  padding: 0 !important;
}
.padding-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.padding-y-g2 {
  padding-top: $offset-g2 !important;
  padding-bottom: $offset-g2 !important;
}
.padding-top-0 {
  padding-top: 0 !important;
}
.padding-bottom-0 {
  padding-bottom: 0 !important;
}
.padding-bottom-g2 {
  padding-bottom: $offset-g2 !important;
}
.padding-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.padding-left-0 {
  padding-left: 0 !important;
}
.padding-right-0 {
  padding-right: 0 !important;
}

.margin-y-1 {
  margin-top: $offset-1 !important;
  margin-bottom: $offset-1 !important;
}
.margin-y-g1 {
  margin-top: $offset-g1 !important;
  margin-bottom: $offset-g1 !important;
}
.margin-y-g2 {
  margin-top: $offset-g2 !important;
  margin-bottom: $offset-g2 !important;
}
.margin-top-0 {
  margin-top: 0px;
}
.margin-top-1 {
  margin-top: $offset-1 !important;
}
.margin-top-2 {
  margin-top: $offset-2 !important;
}
.margin-top-g1 {
  margin-top: $offset-g1 !important;
}
.margin-top-g2 {
  margin-top: $offset-g2 !important;
}
.margin-bottom-0 {
  margin-bottom: 0px !important;
}
.margin-bottom-1 {
  margin-bottom: $offset-1 !important;
}
.margin-bottom-2 {
  margin-bottom: $offset-2 !important;
}
.margin-bottom-g1 {
  margin-bottom: $offset-g1 !important;
}
.margin-bottom-g2 {
  margin-bottom: $offset-g2 !important;
}

.margin-left-0 {
  margin-left: 0px !important;
}
.margin-left-1 {
  margin-left: $offset-1 !important;
}
.margin-left-g1 {
  margin-left: $offset-g1 !important;
}
.margin-right-0 {
  margin-right: 0px !important;
}
.margin-right-1 {
  margin-right: $offset-1 !important;
}
.margin-right-2 {
  margin-right: $offset-2 !important;
}

.border-0 {
  border: 0px !important;
}
.border-top-0 {
  border-top: 0px !important;
}
.border-bottom-0 {
  border-bottom: 0px !important;
}
.border-left-0 {
  border-left: 0px !important;
}
.border-right-0 {
  border-right: 0px !important;
}

.full-width {
  width: 100%;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
