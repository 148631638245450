/* _Navbar.scss */

.navbar {
  padding: 0;
  margin-bottom: 0;
  border: 0;

  .container {
    display: flex;
    background: white;
    @include resp-prop-half("padding", $offset-g1);
    padding-top: 0;

    .navbar-logo-wrap {
      flex-grow: 1;
    }

    .navbar-contact-wrap {
      background: $color_debug2;
      text-align: left;
      flex-grow: 0;

      .navbar-contact {
        h1 {
          @extend .h3;
          font-size: 1em;
          margin: $offset-2 0 0 0;
          text-align: left;
        }
  
        ul.ul-header {
          float: left;
          display: block;
          background: $color_debug;
          margin: 0;
          padding: 0;
  
          li {
            display: inline;
            background: $color_debug2;
            line-height: $offset-g2;
            color: $color_navbar-header-text;
  
            a {
              color: $color_navbar-header-link;
            }
  
            &:after {
              content: "|";
              margin: 0 $offset-1;
            }
          }
          // /li
        }
        // /ul      
      }
      // /.navbar-contact

    }
    // /.navbar-contact-wrap

    @media (max-width: $bp-sm-1) {
      .navbar-logo-wrap {
        flex-grow: 0;
      }

      .navbar-contact-wrap {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: flex-end;

        .navbar-contact {
          h1 {
            display: none;
          }
  
          ul.ul-header {
            display: none;
          }
          // /ul
        }
      }
    }
    // /resp
    
    @media (max-width: $bp-xs-1) {

      .navbar-logo-wrap {
        text-align:center;
      }

      .navbar-contact-wrap {
        display:none;
      }
    }
  }
  // /.container

  // [=] in navbar
  .toggle-button {
    display: none;
  }
}

.navbar-logo-wrap {
  padding: 0;
  background: $color_debug;

  @media (max-width: $bp-lg-1) {
    // text-align: center;
  }

  .navbar-logo {
    background: $color_debug3;

    img {
      // width: 100%;
      // height: auto;
    }

    @media (min-width: $bp-lg) {
      // margin-left: $offset-g2 !important; //bs2do
    }

    @media (min-width: $bp-md) and (max-width: $bp-lg-1) {
      img {
        height: 80px;
        width: auto;
      }
    }

    @media (min-width: $bp-tablet) and (max-width: $bp-md-1) {
      img {
        height: 70px;
        width: auto;
      }
    }

    @media (min-width: $bp-xs) and (max-width: $bp-tablet-1) {
      img {
        height: 60px;
        width: auto;
      }
    }

    @media (max-width: $bp-xs-1) {
      margin: 0 $offset-g1;
      float: left;

      img {
        height: auto;
        width: 60%;
        width: calc(100% - 60px);
      }
    }
  }
}