/* _ImgList.scss */
// ref: https://www.w3schools.com/howto/howto_css_image_overlay_icon.asp

ul.ul-img-list {
  display: flex;
  flex-wrap: wrap;
  margin: $offset-g1 0;

  li {
    flex: 0 0 50%;

    @media (min-width: $bp-xs) and (max-width: $bp-tablet-1) {
      flex: 0 0 50%;
    }
    @media (max-width: $bp-xs-1) {
      flex: 0 0 100%;
    }

    .img-hover-overlay.open-lightbox {
      position: relative;
      width: 100%;

      img {
        display: block;
        max-width: 100%;
        height: auto;
        min-width: 100%;
        object-fit: cover;
        vertical-align: bottom;
      }

      /* The overlay effect (full height and width) - lays on top of the container and over the image */
      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: 0.5s ease;
        background-color: transparent;
        background-color: rgba(38, 55, 98, 0.75);

        /* The icon inside the overlay is positioned in the middle vertically and horizontally */
        .btns {
          width: 50%;
          min-width: 160px;
          font-size: 60px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          button {
            background: transparent;
            outline: none;
            border: none;
          }

          @media (max-width: $bp-tablet-1) {
            // font-size: 30px;
            // width: 60%;
          }

          @media (max-width: $bp-xs-1) {
            // font-size: 30px;
            // width: 60%;
          }

          .btn-download,
          .btn-zoom {
            color: white;
          }
        }
      }

      /* When you mouse over the container, fade in the overlay icon*/
      &:hover {
        cursor: pointer;

        .overlay {
          opacity: 1;
        }
      }
    }
  }
}
