/* _ImgFeatured.scss */

.img-featured {
  &.can-zoom {
    position: relative;
    z-index: 0;

    .btn-zoom {
      position: absolute;
      z-index: 1;
      top: 0px;
      right: 0px;
      background: $color_primary;
      color: white;
      text-align: center;
      width: $offset-4;
      height: $offset-4;
      line-height: $offset-2;
      outline: none;
      border: none;
      padding: $offset-1;

      > svg {
        height: $offset-2;
        width: $offset-2;
        line-height: $offset-2;
      }
    }
  }
}
