/* _NavLeft.scss */

.nav-left-wrap {
  @extend .bg-accent;
  padding: $offset-g1;

  > ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      width: 100%;

      &.home {
        @media (max-width: $bp-lg-1) {
          display: none;
        }
      }

      &.new {
        a {
          // padding-left:0;

          span.detail {
            background: $color_accent3;
            color: white;
            padding: 3px 5px;
            margin-left: 5px;
            // margin-right: 5px;
            border-radius: 5px;
            font-size:9px;
            line-height:9px;
    
            @media (max-width: $bp-lg-1) {
              // display: none;
            }
          }
        }       
      }

      a {
        color: $navbar_color-link;
        padding: 0 $offset-g1;
        line-height: 28px;//$offset-g2//2do - review nav
        display: block;
        width: 100%;
        text-align: right;//off-right = text-align:left;

        &.active {
          color: $navbar_color-link-hover;
        }

        &:hover {
          color: $navbar_color-link-hover;
          @include btn-darken-bg-on-hover();
        }
      }
    }
  }
}

// body
//   ul:not(.ul-footer)
//   li.current-post-ancestor:not(.classic-cars-for-sale):not(.classic-cars-sold),
// body
//   ul:not(.ul-footer)
//   li.current-menu-item:not(.classic-cars-for-sale):not(.classic-cars-sold),
// body.classic-cars-for-sale ul:not(.ul-footer) li.classic-cars-for-sale,
// body.classic-cars-sold ul:not(.ul-footer) li.classic-cars-sold {
//   background: $navbar_color-bg-active;

//   a {
//     color: $navbar_color-link-active;
//   }
// }

/* nav menu */
// body.home .menu .menu-item-home {
//   @media (max-width: $bp-lg-1) {
//     // display:none;
//   }
//   // display:none;
// }
