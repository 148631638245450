/* _Ribbon.scss */
// REF: https://codepen.io/eode9/pen/twkKm

.corner-ribbon-wrap {
  position: relative;
  overflow: hidden;
}

.corner-ribbon {
  width: 160px;
  background: #e43;
  position: absolute;
  top: 10px;
  left: -50px;
  text-align: center;
  line-height: 40px;
  letter-spacing: 1px;
  color: #ffffff;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);

  /* Custom styles */
  &.top-right {
    top: 10px;
    right: -50px;
    left: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

    @media (max-width: $bp-xs-1) {
      transform: none;
      line-height: 20px;
      font-size: 12px;
      right: 0;
      top: 0;
      width: auto;
      padding: 0 5px;
    }
  }
}

/* Colors */
.corner-ribbon.green {
  background: #2c7;
}
.corner-ribbon.red {
  background: #e43;
}
