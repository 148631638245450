/* _typography-ul.scss */

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    line-height: $base-font-line-height;
  }

  &.ul-inline li,
  &.ul-inline-block li {
    padding-right: $offset-1;
  }

  &.ul-inline li {
    display: inline;
  }

  &.ul-inline-block {
    li {
      display: inline-block;
    }
  }

  &.ul-tags li {
    width: auto;
    display: inline-block;
    margin: 0 5px 5px 0;

    a {
      padding: 0 $offset-1;
      text-transform: lowercase;
      display: block;
      background: $color_gray-light;
      // background:yellow;
      @include border-radius(8px);
    }
  }
  /* (END) &.ul-tags li */

  &.ul-bullets {
    li {
      list-style-type: disc;
      list-style-position: inside;
      text-indent: -1em;
      padding-left: 1em;
    }
  }

  &.ul-fa {
    // @extend .ul-clean;

    li {
      a {
        &:before {
          // @include .fa-dummy;//191230
          margin-right: 5px;
        }

        &.print:before {
          content: "\f02f" !important;
        }
        &.download:before {
          content: "\f019" !important;
        }
        &.images:before {
          content: "\f030" !important;
        }
        &.details:before {
          content: "\f15c" !important;
        }
        &.enquire:before {
          content: "\f059" !important;
        }
      }
    }
  }
}
/* (END) ul */
