/* _ItemDetails.scss */

.item-details-img>.row {
  // margin: 0;
}

.row {
  // @include resp-prop-half("padding-bottom", $offset-g2);
  // @include resp-prop-half("padding-left", $offset-g2);
  // @include resp-prop-half("padding-right", $offset-g2);
  // padding-top: 0;

  // background: $color_primary;

  .col-post-img {
    padding: 0;
    @include resp-prop-half("padding-left", $offset-g2);
    // @include resp-prop-half("padding-right", $offset-g2);

    @media (min-width: $bp-sm) {
      // padding-right: $offset-g1;
    }

    @media (max-width: $bp-sm-1) {
      @include resp-prop-half("padding-right", $offset-g2);
      padding-bottom: 1px;
    }

    img {
      width: 100%;
      height: auto;
    }

    // .wrap-iframe.img-details {
    //   position:relative;

    //   img {
    //     visibility:hidden;
    //   }
    //   iframe {
    //     position:absolute;
    //     top:0;
    //     left:0;
    //     width:100%;
    //     height:100%;
    //   }
    // }
  }

  /* (END) .col-post-img */

  .col-post-img-grid {
    padding: 0;
    @include resp-prop-half("padding-left", $offset-g2);
    @include resp-prop-half("padding-right", $offset-g2);
  }

  /* (END) .col-post-img-grid */
}

.row.row-post-video.full {
  padding-top: 0;
  // background: $color_primary;

  // .col-post-videoXXX {
  //   padding: 0;
    // @media (min-width: $bp-sm) {
    //   padding-right: $offset-g1;
    // }
    // @media (max-width: $bp-sm-1) {
    //   padding-bottom: 1px;
    // }
  // }

  /* (END) .col-post-img */
}

.col-post-text {
  @include resp-prop-half("padding", $offset-g1);

  @media (max-width: $bp-xs-1) {
    padding: $offset-g2 0;
  }

  h1 {
    margin-top: 0;
    margin-bottom: $offset-g1;
  }
}

.post-text-body {
  line-height: $base-font-line-height;

  .source {
    display: block;
    font-weight: bold;
    margin: 0 0 $offset-g1 0;
  }

  .excerpt {
    display: block;
    // font-weight: bold;
    margin: 0 0 $offset-g1 0;
  }

  img {
    max-width: 100%;
  }

  .slick-slider {
    margin-bottom: $offset-g1;
  }

  .link-category {
    display: block;
    margin-top: $offset-g2;
  }
}

.item-details-img {
  @include resp-prop-half("padding-bottom", $offset-g2);

  &.carousel {
    // background: white;

    .row.row-post-img {
      // @extend .bg-white;

      .col-post-img {
        padding-bottom: 0;
      }

      /* (END) .col-post-img */

      .col-post-item-extras {
        padding: 0;
      }

      /* (END) .col-post-item-extras */
    }
  }

}

/* Lightbox */
/* BUGFIX */
// BUG touching the buttons on small device causes browser to show address bar
// FIX: Move bar down 15px
.ril-toolbar.ril__toolbar {
  width: 160px;
  right: 0;
  left: auto;
  top: $offset-g1;
}