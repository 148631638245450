/* _NavYop.scss */

$navTopHeight: 44px;

.navTop {
  background-color: $color_accent;

  // .container {
  //   @media (min-width: 1200px){
  //     width: $bp-lg-container;//230313
  //   }
  // }

  nav {    
    box-shadow: none;
    margin: 0;
    padding: 0;
    height: $navTopHeight;
    /* Menu height */
    overflow: hidden;
    /* Don't show anything outside the nav */

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      max-height: $navTopHeight;
      /* Menu height x 2 */
      position: relative;
      /* Position the menu button relative to this item */
  
      li {
        display: inline-block;
    
        a {
          display: inline-block;
          padding: 0 $offset-1;
          color: $color_secondary;
          font-weight: 700;
          letter-spacing: 0.1em;
          text-decoration: none;
          text-transform: uppercase;
          white-space: nowrap;
          line-height: $navTopHeight;
          /* Menu height */
          height: 44px;
          /* Menu height */
  
          &.active {
            color: $navbar_color-link-hover;
          }
  
          &:hover {
            color: $navbar_color-link-hover;
            @include btn-darken-bg-on-hover();
          }
        }
        // /a

        &.home {
          a {
            padding:0 $offset-g1;
          }
        }
        // /.home
        &.more {
          /* The menu button */
          position: absolute;
          /* Move the menu button out of flow */
          right: 0;
          top: 0px;
          /* Move upwards, the same distance as the menu height */
          background-image: linear-gradient(to right, rgba(38, 55, 98, 0) 0, rgba(38, 55, 98, 1) 2em);
          padding-left: $offset-g2;
          // width:100px;//calc($navTopHeight * 2);
          height:$navTopHeight;          
      
          button.toggle-button {
            position:static;
            // right: 0;
            // left:auto;
            // top: 0;
            // display: block;
            width:auto;//$navTopHeight;
            height:100%;
            background:transparent;
            color:white;
            display:flex;
            align-items: center;
            line-height: 44px;
            font-size:1em;
            padding:0 $offset-1;

            span.lbl {
              // display: inline-block;
              // float:left;
              display:block;
              // line-height: 44px;
              // height: 44px;
              font-size:1em;
              // padding-right:50px;
              font-weight: 700;
              text-transform: uppercase;
            }
          
            svg.more.show-menu {
              display:block;
              margin-left:$offset-1;
              font-size:$offset-g2;
            }
          }
        }
        // /.more
       } // /li
    }
    // /ul
  }  

  

  // nav li:nth-last-child(2) {
  //   /* The close button */
  //   display: none;
  // }

  nav#menu:target {
    height: auto;
    padding: 0;
  }

  nav#menu:target ul {
    max-height: none;
  }

  nav#menu:target li {
    display: block;
  }

  nav#menu:target a {
    display: block;
    padding: 0 2em;
    background-color: rgba(255, 255, 255, 0.05);
  }

  nav#menu:target a:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }

  nav#menu:target li:not(:first-child) {
    margin-top: 2px;
  }

  nav#menu:target li:last-child {
    display: none;
  }

  nav#menu:target li:nth-last-child(2) {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    border-left: 2px solid rgb(19, 51, 61);
  }

}

