/* Hero.scss */

.heropanel.isDesktop {
    width: 100%;

    .heropanel--wrap {
        position: relative;
        z-index: 0;
        width: 100%;
        min-height: 300px;
        height: calc(100vh - 160px);

        // max-height: 500px;
        overflow: hidden;
        // background-color: black;
        position: relative;

        @media all and (max-width: $bp-md-1) {
            // height: auto;
            height:40vh;
        }

        @media all and (max-width: $bp-sm-1) {
            height: auto;
        }
    }

    // /.heropanel--wrap

    .heropanel--video {
        position: absolute;
        z-index: 1;
        object-fit: cover;
        object-position: right bottom;
        width: 100%;
        min-width: 100%;
        min-height: 100%;
    }

    .heropanel--content {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 10%;
        transform: translate(0%, -65%);
        

        @media all and (min-width: $bp-lg) {
            // width: 60%;
            top: 50%;
            left: 10%;
            transform: translate(0%, -65%);
        }

        @media all and (max-width: $bp-lg-1) {
            // width: 60%;
            top: 50%;
            left: $offset-g2;
            transform: translate(0%, -50%);
        }

        @media all and (min-width: $bp-tablet) and (max-width: $bp-sm-1) {
            width: 100%;
            top: 0%;
            left: 0%;
            transform: translate(0%, 0%);
            padding: $offset-g2;
        }

        
    }
}

.heropanel.isMobile {
    @include resp-prop-half("padding", $offset-g2);
}

.heropanel {
    .heropanel--content {

        max-width: 80%;
        font-size: 1em;

        @media all and (max-width: $bp-tablet-1) {
            margin-top:25vh;
            max-width: 100%;
        }

        h1 {
            font-size: 1.6em;
            text-transform: uppercase;
            font-weight: 600;
            line-height: 1.4em;
            letter-spacing: 0em;
            color: white;
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

            span {
                font-size: 2em;
                display: block;
                font-weight: 600;
                line-height: 1em;
            }

            @media all and (max-width: $bp-sm-1) {
                font-size: 1.2em;

                span {
                    font-size: 1.6em;
                }
            }

            
        }

        p {
            max-width: 600px;
            // border:1px solid yellow;
            font-size: 1.2em;
            color: white;
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        }

        .heropanel--btns {
            padding: 0 0 $offset-g2 0;
        }
    }
}


.btn--block {
    font-size: 1.2em;
    font-weight: 600;
    font-family: $base-font-family-headers;
    display: inline-block;
    padding: $offset-1 $offset-g1;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: $offset-g1;

    &.white {
        background-color: white;
        color: black;
    }

    &.black {
        background-color: black;
        color: white;
    }
}