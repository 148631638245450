/* _typography.scss */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;600;700&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
  color: black;
  // font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-family: "Poppins", "Century Gothic", "Apple Gothic", sans-serif;
  font-weight: normal;
}

p {
  font-size: $base-font-size;
  color: $base-font-color;
  line-height: $base-font-line-height;

  &:first-of-type {
    margin-top: 0;
  }
}

h1 {
  font-size: 36px;
  line-height: 44px;
  color: $color_post-title;
}

h2 {
  margin: 0 0 0.5em 0;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: $color_accent;
}

h3,
.h3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

h4,
.h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  text-transform: uppercase;
}

h5,
.h5 {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  a {
    color: black;
  }
}

.h5-underline {
  margin: 0 0 5px 0;
  display: inline-block;
  padding: 0 0 5px 0;
  border-bottom: 1px solid $color_layout-border-strong;
}

h6 {
  // background: black;
  // color: white !important;
  padding: 0 5px;
  // display: inline-block;
  font-weight: bold;
}

b,
strong,
.strong {
  font-weight: bold;
  color: black;
}

.line-through {
  text-decoration: line-through;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.fmt-uppercase {
  text-transform: uppercase;
}

.fmt-trim-string {
  @include trim-string();
}
.snippet:after,
.snippetWrap p:after {
  content: "...";
}

ul {
  /* see _typography-ul.scss */
}

.hide-text {
  color: transparent;
  font-size: 0px !important;
}

.txt-nowrap {
  white-space: nowrap;
}
