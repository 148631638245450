/* _Item.scss */
//2do - uniform if no complaints (start: 230331)

$img-height-1: 180px;
$img-height-2: 150px;
$img-height-3: 210px;
$img-height-4: 260px;

.item.item-card {
  @include item-shared-props();

  article {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: auto;
    // border: 1px solid $color_layout-border;

    .card-img {      
      display: flex;
      width: 100%;
      height: auto;

      a {
        display: block;
        width: 100%;

        img {
          width: 100%;
          height: auto;

          // &.img-loading {
          //   width: 100%;
          //   height: 100%;
          // }
        }
      }
    }

    .card-txt {
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      width: 100%;
      padding: $offset-g1 0;
      // background: yellow;
      flex-grow: 1;
      .spacer {
        // display: flex;
        flex-grow: 1;
      }
    }

    .ftr {
      margin: $offset-g1 0 0 0;
      // background: orange;
      padding: 0;
      display: flex;
      justify-content: space-between;
    }
  }
}

// list / gallery grid items
.item.item-card:not(.featured):not(.big) {
  article {
    .card-img {     
      aspect-ratio:4/3;//2do
      
      @media (min-width:$bp-lg){
        min-height: $img-height-1;
      }
      @media (min-width:$bp-xlg){
        height: $img-height-3;
      }
      @media (min-width:$bp-xxlg){
        height: $img-height-4;
      }
      @media (min-width:$bp-md) and (max-width:$bp-lg-1){
        // height: $img-height-2;
        height:auto;
        aspect-ratio:4/3;//2do
      }
      @media (min-width:$bp-sm) and (max-width:$bp-md-1){
        height: $img-height-1;
      }
      @media (min-width:700px) and (max-width:$bp-sm-1){
        height: $img-height-3;
      }
      @media (min-width:$bp-tablet) and (max-width:699px){
        // height: 190px;
        height:auto;
        aspect-ratio:4/3;//2do
      }
    }
  }
}

// list / BIG grid items (homepage)
.item.item-card.big {
  article {
    .card-img {
      background-color:white;
      height:auto;
      aspect-ratio:4/3;//2do

      a {
        img {
          min-height:100%;
        }
      }

      // @media (min-width:$bp-lg) {
      //   height: 400px;
      // }
      // @media (min-width:$bp-md) and (max-width:$bp-lg-1){
      //   height: 320px;
      // }
      // @media (min-width:700px) and (max-width:$bp-md-1){
      //   // height: 220px;
      //   height:auto;
      //   aspect-ratio:4/3;//2do
      // }
      // @media (min-width:$bp-tablet) and (max-width:699px){
      //   height: 190px;
      // }
    }
  }
}

// featured items (homepage slider)
.item.item-card.featured {
  article {
    .card-img {      
      @media (min-width:$bp-lg){
        height: $img-height-1;
      }
      @media (min-width:$bp-xlg){
        height: $img-height-3;
      }
      @media (min-width:$bp-xxlg){
        height: $img-height-4;
      }
      @media (min-width:1024px) and (max-width:$bp-lg-1){
        height: 160px;
      }
      @media (min-width:$bp-md) and (max-width:1023px){
        height: 200px;
      }
      @media (min-width:$bp-sm) and (max-width:$bp-md-1){
        height: $img-height-1;
      }
      @media (min-width:700px) and (max-width:$bp-sm-1){
        height: 135px;
      }
      @media (min-width:$bp-tablet) and (max-width:699px){
        height: 125px;
      }
      @media (min-width:$bp-xs) and (max-width:$bp-tablet-1) {
        // height: 165px;
        height:auto;
        aspect-ratio:4/3;//2do
      }
      @media (min-width:400px) and (max-width:$bp-xs-1) {
        height: 260px;
      }
      @media (min-width:360px) and (max-width:399px) {
        height: 220px;
      }
      @media (max-width:359px) {
        height: 200px;
      }

      img {
        min-height: 100%;
      }
    }
  }
}

.item-card.accent {
  @media (min-width: $bp-tablet) {
    //only show accent grid if on big screen
    background: $card-color_accent-bg;

    article {
      .card-txt {
        padding-left: $offset-g1;
        padding-right: $offset-g1;

        .title a {
          color: $card-color_accent-link-title;
        }

        .price:not(.sold):not(.detail) {
          color: $card-color_accent-txt;
        }
      }

      a.category {
        color: $card-color_accent-link-category;
      }

      .ftr {
        .year {
          color: $card-color_accent-txt;
        }
      }
    }
  }
}

.item-card.custom-link {
  background: $color_accent;
}

.item.item-row {
  @include item-shared-props();
  @include item-row();
}

// list / gallery grid items
.items-wrap.big-items-wrap.Videos,
.container.items.Videos {
  .item.item-card {
    article {
      .card-img {     
        aspect-ratio:16/9;//2do
        min-height:auto;
        height:auto;
      }
    }
  }
}