/* _Pagination.scss */

.pagination-wrap {
  margin: 0 auto $offset-g2 0;
  @include resp-prop-half("padding-left", $offset-g2);
  @include resp-prop-half("padding-right", $offset-g2);

  ul.ul-pagination {
    text-align: center;

    li {
      display: inline-block;
      margin: 0;
      padding: 0;

      &.li-prev-next button,
      &.li-prev-next a,
      &.li-num button,      
      &.li-num a {
        @include btn-disc("pagination-num");
        background-color: transparent;
      }

      &.li-num.active button,
      &.li-num.active a {
        @include btn-disc("pagination-active");
      }
    }
  }
  /* (END) .ul-pagination */
}
/* (END) .pagination-wrap */
