/* _bootstrap-overrule.scss */

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.padding-g1 {
  padding: $offset-g1 !important;
}

.padding-g2 {
  padding: $offset-g2 !important;
}

.padding-x-g1 {
  padding-left: $offset-g1 !important;
  padding-right: $offset-g1 !important;
}

.padding-x-g2 {
  padding-left: $offset-g2 !important;
  padding-right: $offset-g2 !important;
}

.padding-top-g2 {
  padding-top: $offset-g2 !important;
}

.col-no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-x-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.row-no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.page-header {
  padding-bottom: $offset-1;
  margin-top: $offset-g2;
  margin-bottom: $offset-g2;
  border-bottom: 1px solid $color_layout-border;

  @media (max-width: $bp-xs-1) {
    margin-bottom: $offset-g1;
  }
}

.col-tablet-12 {
  @media (max-width: $bp-tablet-1) {
    width: 100%;
  }
}
.col-xxs-12 {
  @media (max-width: $bp-xxs-1) {
    width: 100%;
  }
}
.col-xxs-6 {
  @media (max-width: $bp-xxs-1) {
    width: 50%;
  }
}
