/* _mixins.scss */

@mixin gutter-pad($prop) {
  #{$prop}: $offset-g2;
  @media (min-width: $bp-tablet) {
    #{$prop}: $offset-g2;
  }
  @media (max-width: $bp-tablet-1) {
    #{$prop}: $offset-g1;
  }
}

@mixin gutter-pad-half($prop) {
  #{$prop}: $offset-g1;
  @media (min-width: $bp-tablet) {
    #{$prop}: $offset-g1;
  }
  @media (max-width: $bp-tablet-1) {
    #{$prop}: 0;
  }
}

@mixin resp-prop-half($prop, $val) {
  #{$prop}: $val;
  @media (max-width: $bp-tablet-1) {
    #{$prop}: calc(#{$val} / 2);
  }
}

@mixin body-shadow() {
  -webkit-box-shadow: 0px 10px 37px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 37px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 37px -4px rgba(0, 0, 0, 0.75);
}

@mixin bg-gradient-y() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+1,f0f0f0+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #ffffff 1%,
    #f0f0f0 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #ffffff 1%,
    #f0f0f0 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffffff 1%,
    #f0f0f0 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f0f0f0',GradientType=0 ); /* IE6-9 */
}

@mixin bg-gradient-x() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+1,f0f0f0+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #ffffff 1%,
    #f0f0f0 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #ffffff 1%,
    #f0f0f0 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #ffffff 1%,
    #f0f0f0 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f0f0f0',GradientType=1 ); /* IE6-9 */
}

@mixin bg-gradient-x-accent() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a4d1f8+1,198df2+100 */
  background: $color_secondary; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #a4d1f8 1%,
    #198df2 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #a4d1f8 1%,
    #198df2 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #a4d1f8 1%,
    #198df2 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a4d1f8', endColorstr='#198df2',GradientType=1 ); /* IE6-9 */
}

@mixin rwd_border-content-divide($position: "top") {
  @if $position == "bottom" {
    border-top: 2px solid $color_layout-border-strongest;
  } @else if $position == "left" {
    border-left: 2px solid $color_layout-border-strongest;
  } @else if $position == "right" {
    border-right: 2px solid $color_layout-border-strongest;
  } @else {
    border-top: 2px solid $color_layout-border-strongest;
  }
}

@mixin box-shadow($inset: true) {
  //https://www.cssmatic.com/box-shadow
  @if $inset == true {
    -webkit-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  } @else if $inset == hover {
    -webkit-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.3);
  } @else if $inset == false {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    transition: none;
  }
}

@mixin navbar-dd-box-shadow() {
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin box-shadow-input-focus() {
  border-color: rgba(81, 203, 238, 1);
  -webkit-box-shadow: 0 0 5px 0 rgba(81, 203, 238, 1);
  box-shadow: 0 0 5px 0 rgba(81, 203, 238, 1);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radius-position($radius, $position) {
  @if $position == "top" {
    -webkit-border-top-left-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    -moz-border-radius-topleft: $radius;
    -moz-border-radius-topright: $radius;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  } @else if $position == "bottom" {
    -webkit-border-bottom-right-radius: $radius;
    -webkit-border-bottom-left-radius: $radius;
    -moz-border-radius-bottomright: $radius;
    -moz-border-radius-bottomleft: $radius;
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  } @else if $position == "right" {
    -webkit-border-top-right-radius: $radius;
    -webkit-border-bottom-right-radius: $radius;
    -moz-border-radius-topright: $radius;
    -moz-border-radius-bottomright: $radius;
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  } @else if $position == "left" {
    -webkit-border-top-left-radius: $radius;
    -webkit-border-bottom-left-radius: $radius;
    -moz-border-radius-topleft: $radius;
    -moz-border-radius-bottomleft: $radius;
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

@mixin bg-tint($color: black, $opacity: "full") {
  @if $opacity == "full" {
    $opacity: 1;
  } @else if $opacity == "twothir" {
    $opacity: 0.75;
  } @else if $opacity == "semi" {
    $opacity: 0.5;
  } @else if $opacity == "third" {
    $opacity: 0.33;
  } @else if $opacity == "zero" {
    $opacity: 0;
  }

  @if $color == "white" {
    /* Fallback for web browsers that don't support RGBa */
    background-color: rgb(255, 255, 255);
    /* RGBa with 0.6 opacity */
    background-color: rgba(255, 255, 255, $opacity);
  } @else {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, $opacity);
  }
}

@mixin hide-text() {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin trim-string() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin trim-string-remove() {
  overflow: visible;
  text-overflow: ellipsis;
  white-space: normal;
}

@mixin a-on-primary() {
  color: $color_secondary;
}

@mixin a-on-secondary() {
  color: $color_primary;
}
