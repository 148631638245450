/* _ImgGrid.scss */
//2do - uniform if no complaints (start: 230331)

ul.ul-img-grid {
  display: grid;
  grid-gap: 0px;
  grid-template-columns:repeat(6,1fr);

  @media (min-width: $bp-tablet) and (max-width: $bp-sm-1) {
    grid-template-columns:repeat(10,1fr);
  }
  @media (min-width: $bp-xs) and (max-width: $bp-tablet-1) {
    grid-template-columns:repeat(6,1fr);
  }

  li {
    aspect-ratio:1/1;//2do
    position:relative;
    overflow:hidden;

    img {
      position:absolute;
      top:0%;
      left:-20%;
      width:140%;
      height:auto;
      // height:150%;
      aspect-ratio:4/3;//2do
    }

    &.btn-youtube {
      background:$color_youtube;
      position:relative;
      cursor: pointer;
  
      img {
        visibility:hidden;
      }
  
      svg {
        position:absolute;
        top:10%;
        left:10%;
        width:80%;
        height:80%;
        color:white;
      }
    }
  }
}

// ul.ul-img-gridXXX {
//   display: flex;
//   flex-wrap: wrap;

//   li {
//     flex: 0 0 25%;
//     @media (min-width: $bp-tablet) and (max-width: $bp-sm-1) {
//       flex: 0 0 10%;
//     }
//     @media (min-width: $bp-xs) and (max-width: $bp-tablet-1) {
//       flex: 0 0 20%;
//     }
//   }

//   img {
//     display: block;
//     max-width: 100%;
//     height: auto;
//     min-width: 100%;
//     object-fit: cover;
//     vertical-align: bottom;
//     cursor: pointer;
//   }

//   li.btn-youtube {
//     background:$color_youtube;
//     position:relative;
//     cursor: pointer;

//     img {
//       visibility:hidden;
//     }

//     svg {
//       position:absolute;
//       top:10%;
//       left:10%;
//       width:80%;
//       height:80%;
//       color:white;
//     }
//   }
  
// }
