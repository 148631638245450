/* _Carousel.scss */

$opacity-btn-default: 0.4;
$opacity-btn-approach: 0.3;
$opacity-btn-hover: 1;
$opacity-btn-active: 1;

.slick-slider-base {
  z-index: 0;
  height: 100%;

  &.aspect-ratio-4-3 {
    .slick-list {  
      > .slick-track > .slick-slide > div > div {
        text-align:center;
        background:rgba(0,0,0,0.2);
        aspect-ratio: 4/3;
      }
  
      .slick-slide img {
        width:auto;
        height: 100%;
        max-width: 100%;
        margin:0 auto;
        display: inline-block;
      }
    }
  }

  .slick-list {
    z-index: 0;
    height:100%;

    /* kill margin below image */
    > .slick-track > .slick-slide > div > div {
      display: block !important;
    }

    .slick-slide img {
      width: 100%;
      height: auto;
    }
  }

  .slick-prev {
    left: $offset-g2;
  }

  .slick-next {
    right: $offset-g2;
  }

  .slick-prev,
  .slick-next {
    z-index: 2;
    top: 50%;
    margin: 0;
    width: 40px;
    height: 40px;
    opacity: $opacity-btn-default;

    &:before {
      font-size: 40px;
    }
  }

  .slick-dots {
    bottom: $offset-g1;

    li button:before {
      font-size: 15px;
      color: white;
      opacity: $opacity-btn-default;
    }

    li.slick-active button:before {
      color: white;
      opacity: $opacity-btn-active;
    }
  }

  &:hover .slick-prev,
  &:hover .slick-next,
  .slick-dots li button:before {
    opacity: $opacity-btn-approach;
  }

  .slick-prev:hover,
  .slick-next:hover,
  .slick-dots li:hover button:before {
    opacity: $opacity-btn-hover;
  }
}

.slick-slider.carousel {
  @extend .slick-slider-base;
}
