/* _VideoEmbed.scss */


.wrap-iframe {
  position:relative;
  width: 100%;
  min-height: 300px;  
  z-index:0;
  text-align: center;
  aspect-ratio:16/9;//2do

  @media(min-width:$bp-tablet-1) {
    // height:calc(50vh - 0);
  }  

  // @media(min-width:$bp-tablet-1) {
  //   height: 440px;
  // }
  
  @media (max-width:$bp-tablet-1){
    height: 300px;
  } 

  img {
    visibility:hidden;
    z-index:0;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    margin: 0 auto;    
  }
  iframe {
    position:absolute;
    z-index:1;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}