/* _body-shadow.scss */

body {
    // @include body-shadow();
    margin:0 auto;
    width: 100%;

    // @media (min-width: $bp-sm) {
    //     width: $bp-sm-container;
    // }

    // @media (min-width: $bp-md) {
    //     width: $bp-md-container;
    // }

    // @media (min-width: $bp-lg) {
    //     width: 100%;
    // }    
}
/* [END] body */