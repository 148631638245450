/* _Footer.scss */

footer {
  // margin-top:$offset-g1;

  .container {
    background: $color_accent2;
    padding-bottom: $offset-g2;
    color: white;

    > .row {
      padding: 0;

      > div {
        padding-top: $offset-g2;
        padding-bottom: $offset-g2;
        @include resp-prop-half("padding-left", $offset-g2);
        @include resp-prop-half("padding-right", $offset-g2);

        &.col-ftr-contact {
          text-align: right;

          @media (max-width: $bp-tablet-1) {
            padding-top: 0;
            text-align: left;
          }
        }

        > .title-with-fa-links {
          display: block;
          width: 100%;
          float: left;

          h4 {
            float: left;
            margin-right: $offset-1;
          }
        }

        h4 {
          color: white;
          font-weight: bold;
          line-height: $offset-g2;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
    /* (END) .row */

    ul:not(.social_menu) {
      list-style: none;
      margin: 0;
      padding: 0;

      &.ul-footer:not(.social_menu) {
        display: block;
        float: left;
        width: 100%;
      }

      li {
        margin: 0;

        a {
          color:white;
        }

        &.li-inline {
          a {
            display: inline;            
          }
          a:nth-child(2):before {
            content: "|";
            margin: 0 $offset-1;
          }
        }

        > a,
        > span {
          color: white;
          display: block;
          padding: 5px 0;
          // line-height:$offset-g2;
        }

        > a:active {
          // background:transparent;
          color: white;
        }
      }

      &.social_menu {
        li {
          color: white;
        }
      }
    }
    /* (END) ul */
  }
}
/* [END] #socket */
