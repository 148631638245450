/* _base-colors.scss */

.color-danger {color: $color_danger}
.color-primary {color:$color_primary}
.color-base {color:$base-font-color}
.color-hyperlink {color:$color_hyperlink-text}
.color-white {color:white;}

.bg-white { background:white }
.bg-secondary { background: $color_secondary; }
.bg-secondary-light {background:$color_secondary-light;}
.bg-gray-light {background: $color_gray-light}
.bg-transparent { background:transparent;}