/* _Breadcrumbs.scss */

.row-breadcrumb {
  .col-post-breadcrumb {
    margin-bottom: 0;
    @include resp-prop-half("padding", $offset-g1);
    // background: $color_secondary;

    @media (max-width: $bp-xs-1) {
      padding: 5px 0;
      // margin-bottom:$offset-g1;
    }

    .crumbs-wrap {
      ul {
        // @include trim-string();
        overflow: hidden;
        /* text-overflow: ellipsis; */
        white-space: nowrap;

        li {
          @include resp-prop-half("padding-right", $offset-1);
          display: inline-block;

          a {
            @include a-on-secondary();
          }

          &.home {
            .i-home {
              margin-right: 0;
            }

            @media (min-width: $bp-sm) {
              .i-home {
                display: none;
              }
            }
            @media (max-width: $bp-sm-1) {
              span {
                display: none;
              }
            }
          }
          /* (END) .home */

          svg {
            height: $offset-1;
            margin-right: $offset-1;
            @media (max-width: $bp-tablet-1) {
              margin-right: 5px;
            }
          }

          &.li-jump-menu-wrap {
            form {
              display: inline-block;

              label {display:none}

              .form-group {
                margin-bottom: 0 !important;
                select {
                  max-width: 130px;
                }
              }
            }
          }
          /* (END) &.li-jump-menu-wrap */
        }

        // on posts page
        // &.has-3-crumbs {
        @media (max-width: $bp-sm-1) {
          li.Live,
          li.Archive {
            .crumb-resp > span {
              display: none;
            }
          }

          li.Live {
            .crumb-resp:before {
              content: "For Sale";
            }
          }

          li.Archive {
            .crumb-resp:before {
              content: "Sold";
            }
          }
        }
        // }

        // on single post page
        &.has-4-crumbs {
          @media (max-width: $bp-tablet-1) {
            // li.Live,
            // li.Archive {
            //   display: none;
            // }
            li.crumb-item {
              display: none;
            }
          }
        }
      }
    }
    /* (END) .crumbs-wrap */
  }
  /* (END) .col-post-breadcrumb */
}

.content-page,
.content-items-list {
  .row-breadcrumb {
    .col-post-breadcrumb {
      // @include resp-prop-half("padding-left", $offset-g2);
    }
  }
}