/* _ItemExtras.scss */

.price {
  color: #666;

  &.detail:not(.sold) {
    color: #f90;
  }

  &.sold {
    color: red;
    font-weight: bold;
  }
}

.item-extras {
  h5 {
    @extend .h5-underline;
  }

  div.feature-list {
    > div {
      padding-top: $offset-1;
      padding-bottom: $offset-1;
      border-top: 1px solid $color_layout-border;
      display: block;

      h3 {
        margin: 0;
      }

      &:first-child {
        padding-top: 0;
        border-top: none;
      }
      &:last-child {
        padding-bottom: 0;
      }

      &.post-btns {
        a.icon-text {
          white-space: nowrap;
        }

        ul.big-btns {
          li {
            display:block;
          }
          a {
            line-height:2em;
            svg {
              font-size:2em;
              float:left;
            }

            &.youtube {
              svg {
                color:$color_youtube
              }
            }
          }
        }
      }

      &.post-share {
        // width:110px;
        h4 {
          margin: 0 0 $offset-1 0;
        }

        ul {
          @extend .ul-inline-block;
          line-height: 0;

          li {
            line-height: 0;
            padding: 0 5px 0 0;
            @media (min-width: $bp-tablet) and (max-width: $bp-lg-1) {
              padding-right: 2px;
            }
            &:last-of-type {
              padding-right: 0;
            }
          }
        }
      }
      /* (END) .post-share */
    }
  }

  //price
  h3 {
    // display:inline-block;
    margin: 0;
    // border-bottom:1px solid $color_layout-border;
  }

  hr {
    margin-top: $offset-1;
    margin-bottom: $offset-1;
    border-color: $color_layout-border;
  }

  p {
    margin-bottom: 0;
  }
}
