/* _Alert.scss */

.alert {
  position: relative;
  padding: $offset-1 $offset-g1;
  margin-bottom: $offset-g1;
  border: 1px solid transparent;
  @include border-radius(5px);

  img.loading {
    margin-right: $offset-g1;
  }
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
