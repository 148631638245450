/* _typography-hyperlink.scss */


a {
    color:$color_hyperlink-text;
    cursor:pointer;

    &:hover {
        color:$color_hyperlink-text-hover;
    }

    &:active {
        background-color:$color_hyperlink-text-hover;
        color:white;
    }
}


.a-muted {
    color: $color_hyperlink-text-muted;
    > span {
        color:$color_hyperlink-text-muted;
    }

    &:hover {
        color:$color_hyperlink-text-hover;
        > span {
            color:$color_hyperlink-text-hover;
        }
    }
}

.a-black {
    color: black;
    > span {
        color: black;
    }

    &:hover {
        color:$color_hyperlink-text-hover;
        > * {
            color:$color_hyperlink-text-hover;
        }
    }

    &:active {
        background-color:$color_hyperlink-text-hover;
        color:white;
        > * {
            color:white;
        }
    }
}

.a-blackblock {
    background: black;                        
    font-size: 12px;
    line-height: 12px;
    padding: 4px 8px;
    color:white;

    &:hover {
        background: $color_hyperlink-text-hover;
        text-decoration:none;
        color: white;
    }
}

.underline {
    text-decoration:underline;
}

.g_hyperlink-base {
    color:$color_hyperlink-text;
    > span {
        color:$color_hyperlink-text;
    }
    
    @extend .g_hyperlink-base-hover;

    &[target^="blank"] {

    }
}

.g_hyperlink-base-hover {
    &:hover {
        color:$color_hyperlink-text-hover;
        text-decoration:underline;

        &:before,
        > i,
        > span {
            color:$color_hyperlink-text-hover;
        }
    }
}