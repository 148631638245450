/* _TitleSplitter.scss */

.title_splitter_wrap {
  padding-top: $offset-g2;
  @include resp-prop-half("padding-bottom", $offset-g1);
  @include resp-prop-half("padding-left", $offset-g2);
  @include resp-prop-half("padding-right", $offset-g2);
  width: 100%;
  background: white;
  box-sizing: border-box;

  .title-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
      white-space: nowrap;
      padding-right: $offset-g1;
      text-transform: uppercase;

      h2 {
        @extend .h4;
        @extend .a-black;
        margin: 0;
        background-color: transparent;
      }

      &.icon {
        a {
          display:flex;

          svg {
            font-size: 20px;
            @extend .a-black;
            margin-right:5px;
          }
          h2 {
            display:inline-block;
          }
        }
        
        &--videos a {
          svg {
            color:$color_youtube;
          }
        }
      }
    }
    /* (END) .title */

    .spacer-line {
      flex-grow: 1;
      height: 1px;
      background: $color_layout-border-strong;
      align-self: center;
    }

    .see-all {
      white-space: nowrap;
      padding-left: $offset-g1;

      > a {
        @extend .a-black;
      }

      @media (max-width: $bp-xs-1) {
        display: none;
      }
    }
    /* (END) .see-all */
  }

  .body {
    padding-top: $offset-g1;
  }
}

.content-inner {
  .title_splitter_wrap {
    padding-left:0;
    padding-right:0;
  }
}