/* _variables.scss */

/* base font */
$base-font-size: 14px;
$base-font-size-small: 12px;
$base-font-family: "Open Sans", Arial, Helvetica, sans-serif;
$base-font-family-headers: "Poppins", "Century Gothic", "Apple Gothic", sans-serif;
$base-font-color: #333;
$base-font-line-height: 24px;

/* responsive breakpoints */
$bp-xxlg: 1600px;
$bp-xlg: 1440px;

$bp-lg: 1200px;
$bp-lg-1: 1199px;
$bp-lg-container: 80%;//1170px

$bp-md: 992px;
$bp-md-1: 991px;
$bp-md-container: 970px;

$bp-sm: 768px;
$bp-sm-1: 767px;
$bp-sm-container: 750px;

$bp-xs: 480px;
$bp-xs-1: 479px;
$bp-tablet: 640px;
$bp-tablet-1: 639px;
$bp-xxs: 400px;
$bp-xxs-1: 399px;
$bp-xxxs: 320px;
$bp-xxxs-1: 319px;

/* offset: margins & paddings */
$offset-0: 0px;
$offset-0-5: 5px;
$offset-1: 10px;
$offset-2: 20px;
$offset-3: 30px;
$offset-4: 40px;
$offset-g1: 15px;
$offset-g2: 30px;
$offset-g3: 45px;
$offset-g4: 60px;

/* forms */
$form-input-gap: $offset-1;

/* border-radius */
$border-radius-panel: 3px;
$border-radius-input: 3px;
$border-radius-button: 25px;

/* modal */
$modal-width-default: 470px;
$modal-width-xlg: 720px;
$modal-width-lg: 720px;
$modal-width-md: 640px;
$modal-width-sm: 580px;
$modal-width-xs: 435px;
$modal-width-xxs: 100%;
