/* _SocialBtns.scss */

//REF: https://codepen.io/Deadlymuffin/pen/hGiqo/
ul.social_menu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;

  &.on-dark-bg {
    li a {
      color: white !important;
    }
  }

  li {
    float: left;
    display: block;
    width: $offset-g2;
    height: $offset-g2;
    line-height: $offset-g2;
    margin: 0 5px;
    &:first-of-type {
      margin-left: 0;
    }

    a {
      @include btn-animation();
      color: #000;
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: block;
      text-align: center;
      font-size: 20px;

      svg {
        height: 100%;
      }

      &:hover {
        svg {
          color: white !important;
        }
      }
    }

    &.facebook a {
      color: $color_facebook; //#3d5b99;
      &:hover {
        background: $color_facebook; //#3d5b99;
      }
    }
    &.twitter a {
      color: $color_twitter; //#00aced;
      &:hover {
        background: $color_twitter; //#00aced;
      }
    }
    &.instagram a {
      color: $color_instagram; //#00aced;
      &:hover {
        background: $color_instagram; //#00aced;
      }
    }
    &.youtube a {
      color: $color_youtube; //#e64a41;
      &:hover {
        background: $color_youtube; //#e64a41;
      }
    }

    @media (max-width: $bp-sm-1) {
      width: $offset-g3;
      height: $offset-g3;
      line-height: $offset-g3;
      margin: 0;

      a {
        font-size: 30px;
      }
    }
  }
}
/* (END) ul */
