/* _ItemMixins.scss */

$card-color-txt: #666;
$card-color-txt-price-detail: #f90;
$card-color-txt-price-sold: #f00;

$card-color_accent-bg: $color_accent;
$card-color_accent-link-title: white;
$card-color_accent-txt: #ccc;
$card-color_accent-link-category: $color_secondary;

@mixin item-shared-props() {
  article {
    .card-img {
      overflow: hidden;
      min-height: auto;
      padding: 0;
      // background-color: $card-color-txt;

      a {
        &:hover img {
          transform: scale(1.1);
        }
        img {
          transition: all 0.3s ease;
          width: 100%;
          height: auto;
          // min-height: 100%;
        }
      }
    }
    .card-txt {
      .title {
        display: block;
        margin: 0 0 5px 0;
      }

      .source {
        color: $color_accent3;
        font-weight: bold;
      }

      .dateHistory {
        color: red;
        font-weight: bold;
      }

      .price {
        color: $card-color-txt;
        &.detail {
          color: $card-color-txt-price-detail;
        }
        &.sold {
          color: $card-color-txt-price-sold;
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      p {
        margin-top: 0;
      }
    }

    .category {
      text-transform: uppercase;
    }

    .ftr {
      .year {
        color: $card-color-txt;
      }
    }
  }

  &.custom-link {
    article {
      .card-img {
        background:transparent;
      }
      .card-txt {
        .title {
          text-align:center;
          a {
            color:white;
          }
        }
      }
    }
  }
}

@mixin item-card() {
  @include resp-prop-half("margin", $offset-g1);
  flex: inherit; //1 0 500px;// flex: 0 0 30%;
  box-sizing: border-box;
  padding: 0;

  &.big {
    @media (min-width: $bp-tablet) {
      width: calc(50% - #{$offset-g2});
    }
  }
  // &.six {
  //   @media (min-width: $bp-md) {
  //     width: calc(16.66666666% - #{$offset-g2});
  //   }
  // }
  &:not(.big) {
    // &:not(.six) {
    @media (min-width: $bp-sm) {
      width: calc(33.33333333% - #{$offset-g2});
    }
    // }
    @media (min-width: $bp-tablet) and (max-width: $bp-sm-1) {
      width: calc(50% - #{$offset-g2});
    }
  }

  @media (max-width: $bp-tablet-1) {
    margin: 0 0 $offset-g1 0;
    width: 100%;
    @include item-row();
  }
}

@mixin item-row() {
  @include resp-prop-half("margin-bottom", $offset-g2);
  flex: inherit; //1 0 500px;// flex: 0 0 30%;
  width: 100%;

  article {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: auto;
    // border: 1px solid $color_layout-border;

    .card-img {
      width: 30%;
      min-height: 70px;
      max-height: 200px;
      background-color: transparent;

      a {
        img {
          transform-origin: 50% 100%;
          min-height:auto;
        }
      }
    }

    .card-txt {
      width: 70%;
      align-self: auto;
      padding: 0 $offset-g1;
      @media (max-width: $bp-tablet-1) {
        padding-right: 0;
      }

      .ftr {
        margin-top: 0;
      }
    }
  }
}
