/* _Widget.scss */

.row-widgets {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .widget {
    // width: 33.33333333%;
    display: flex;
    padding: 0.5em;
    width: 100%;
  }
}

.widget {
  padding: $offset-g1;
  border: 1px solid $color_layout-border;
  background: #f9f9f9;

  &.logo-youtube {
    background:white;
    a {
      background-image: url(../../../assets/images/logo-youtube-watch.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-color: white;
      display:block;
      width:100%;
      height:40px;
      max-width: 200px;

      span {
        display:none;
      }
    }
  }

  &.clean {
    padding: 0;
    background: transparent;
    border: none;
  }

  h1 {
    display: none;
    font-size: 1.4em;
  }

  h5 {
    @extend .h5-underline;
  }

  &.title-green h5 {
    color: $color_good;
  }
  &.title-red h5 {
    color: $color_danger;
  }

  p {
    color: #666;
  }

  ul {
    // margin:0;
    // padding:0;
    // list-style:none;
  }

  ul.post-meta {
    display: none;
  }

  // REF: https://getbootstrap.com/docs/4.0/components/input-group/
  .input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

    > .custom-select:not(:last-child),
    > .form-control:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    > .custom-file,
    > .custom-select,
    > .form-control {
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      width: 1%;
      margin-bottom: 0;
      border-color: $color_primary;
    }

    > .form-control,
    > .input-group-append .btn {
      padding: 5px $offset-1;
      @include border-radius(0px);
    }

    > .input-group-append {
      margin-left: -1px;
      .btn {
        background: $color_primary;
        color: white;
      }
    }

    > .input-group-append,
    .input-group-prepend {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }
}
