/* _InfoBoxes.scss */

//REF: https://tobiasahlin.com/blog/flexbox-break-to-new-row/

// section.generic-rowXXX {
//   // padding-top: 0;
//   // padding-bottom: 0;
//   @include resp-prop-half("padding-left", $offset-g1);
//   @include resp-prop-half("padding-right", $offset-g1);
//   width: 100%;
//   background: white;
//   box-sizing: border-box;

//   // &.pad-x-2 {
//   //   @include resp-prop-half("padding-left", $offset-g2);
//   //   @include resp-prop-half("padding-right", $offset-g2);
//   // }

//   // &.pad-y {
//   //   @include resp-prop-half("padding-top", $offset-g1);
//   //   @include resp-prop-half("padding-bottom", $offset-g1);
//   // }
//   // &.pad-y-2 {
//   //   @include resp-prop-half("padding-top", $offset-g2);
//   //   @include resp-prop-half("padding-bottom", $offset-g2);
//   // }
//   // &.pad-bottom-2 {
//   //   @include resp-prop-half("padding-bottom", $offset-g2);
//   // }
// }

.flex-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  @include resp-prop-half("padding-left", $offset-g1);
  @include resp-prop-half("padding-right", $offset-g1);

  .break {
    &.sm-down {
      @media (max-width: $bp-sm-1) {
        flex-basis: 100%;
        @include resp-prop-half("height", $offset-g2);
      }
    }
    &.xs-down {
      @media (max-width: $bp-xs-1) {
        flex-basis: 100%;
        @include resp-prop-half("height", $offset-g2);
      }
    }
  }
  .list-item {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    @include resp-prop-half("padding-left", $offset-g1);
    @include resp-prop-half("padding-right", $offset-g1);
    width: 100%;

    &.flex-tablet-6 {
      @media (min-width: $bp-xs) and (max-width: $bp-sm-1) {
        width: 50%;
      }
    }

    &.flex-tablet-12 {
      @media (max-width: $bp-sm-1) {
        width: 100%;
      }
    }

    &.flex-tablet-hidden {
      @media (max-width: $bp-sm-1) {
        display: none;
      }
    }

    &.flex-sm-6 {
      @media (min-width: $bp-sm) {
        width: 50%;
      }
    }

    &.flex-sm-4 {
      @media (min-width: $bp-sm) {
        width: 33.33333333%;
      }
    }
  }

  .list-content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    //   padding: 1em;
    width: 100%;

    .widget {
      height: 100%;
    }
  }
  .list-content p {
    flex: 1 0 auto;
  }
}
