/* _btn.scss */

a,
.btn {
  &.icon-text {
    svg {
      margin-right: 5px;
    }
  }

  &.text-icon {
    svg {
      margin-left: 5px;
    }
  }
}

.btn {
  @include resp-prop-half("padding", $offset-g1);
  outline: 0;
  border: 0;

  &.icon-md {
    @media (min-width: $bp-tablet) {
      svg {
        font-size: $offset-2;
      }
    }
  }
}
