/* _ItemRelated.scss */
// nested style within _ItemExtras.scss

.item-extras {
  &.item-related {
    margin-top:$offset-g2;//ensure related items have space below
    display: block;
    width: 100%;

    > div.ir-wrap-all:not(:first-child) {
      margin-top:$offset-g2;//ensure related items have space below
    }

    .ir-wrap {
      &.icon-overlay {
        .ir-img {
          position:relative;    
        }    
      }
      .ir-img {
        margin: $offset-1 0;
        a {
          display: block;
          img {
            width: 100%;
            height: auto;
          }
        }   
        
        svg {
          position:absolute;
          bottom:0;
          left:0;
          &.youtube {
            background: $color_youtube;
            color: white;
            padding: 5px;
            width: 40px;
            height: 40px;
          }
        }
      }
      .ir-txt {
        display: block;
      }

      @media (max-width: $bp-tablet-1) {
        display: flex;
        flex-direction: row;

        .ir-img {
          width: 30%;
        }
        .ir-txt {
          width: 70%;
          padding: 0 $offset-g1;
        }
      }
    }
  }
}
