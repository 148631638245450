/* _Loading.scss */

.loading-wrap {
  display: block;
  width: 100%;
  text-align: center;
  @include resp-prop-half("padding", $offset-g2);

  h4 {
    margin: 0 auto;
  }
  img {
    margin: $offset-g1 auto;
  }
}
