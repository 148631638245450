/* _Sidebar.scss */

.col-sidebar {
  @extend .bg-accent;
}

.sidebar {
  .widget {
    margin: $offset-g1 0 $offset-g2 0;

    &:first-child {
      margin-top:$offset-g2;
    }

    &:last-child {
      
    }

    &.christmas-hours {
      ul {
        li {
          font-size:12px;
        }
      }
    }
  }
}
